<div class="projects">
  <div class="projects__header">
    <ng-content></ng-content>
  </div>
  <swiper-container class="swiper-projects">
    @for(project of (colorFilteredProjects | async); track project?.uuid){
      <swiper-slide>
        <div class="container" [routerLink]="['/projects', project.uuid]">
          <div
          div [style.background-color]="project.color ?? defaultProjectColor"
          class="background-color"
          >
            <div
              class="image"
              [style.background-image]="'url(' + project.major_image_url + ')'"
            ></div>
          </div>
          <div class="description">
            <h4>
              {{ project.short_name }}
            </h4>
          </div>
        </div>
      </swiper-slide>
    }
  </swiper-container>
</div>
