import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import Swiper from 'swiper';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { SwiperContainer } from 'swiper/element';
import { Project } from '@interfaces/project.interface';
import { BACKGROUND_COLORS } from '@const/backgroud-colors.const';
import { map, Observable, of } from 'rxjs';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-projects',
  standalone: true,
  imports: [MatIconModule, RouterModule, AsyncPipe],
  templateUrl: './projects.component.html',
  styleUrl: './projects.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProjectsComponent {
  @Input() public projects: Observable<Project[]> | null = null;
  public colorFilteredProjects: Observable<Project[]> | null = null;

  protected readonly defaultProjectColor = BACKGROUND_COLORS[0];

  public swiperProject!: Swiper;

  constructor() {}

  ngOnInit(): void {
    this.colorFilteredProjects = this.getColor();
  }

  ngAfterContentInit(): void {
    const sliders = document.querySelectorAll('.swiper-projects');
    const prevArrow = document.querySelectorAll('.prev-slide');
    const nextArrow = document.querySelectorAll('.next-slide');

    sliders.forEach((slider, idx) => {
      const swiperParams = {
        slidesPerView: window.innerWidth > 1000 ? 2 : 1,
        spaceBetween: 40,
        loop: true,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        },
      };

      prevArrow[idx].addEventListener('click', () => {
        (slider as SwiperContainer).swiper.slidePrev();
      });

      nextArrow[idx].addEventListener('click', () => {
        (slider as SwiperContainer).swiper.slideNext();
      });

      Object.assign(slider, swiperParams);
      (slider as SwiperContainer).initialize();
    });

    prevArrow.forEach((prev) => {
      prev.addEventListener('click', () => {});
    });
  }

  public getColor(): Observable<Project[]> | null {
    if(this.projects) {
      return this.projects.pipe(
        map(projects =>
          projects.map((project, index) => ({
            ...project,
            major_image_url:
            project.major_image_url
              ? project.major_image_url
              : '/assets/logo-plug.svg',
            color:
              index < BACKGROUND_COLORS.length
                ? BACKGROUND_COLORS[index]
                : BACKGROUND_COLORS[
                    index -
                      Math.floor(index / BACKGROUND_COLORS.length) *
                        BACKGROUND_COLORS.length
                  ],
          }))
        )
      );
    }
      return of([]);

  }
}
